import React from 'react'
// Import Swiper React components
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';


// Import Swiper styles

import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/scrollbar/scrollbar.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import './swiper.scss'; // core Swiper

export default () => {


  let names =
  ['Forbes',
  'Gazele Biznesu',
  'CWB',
  'Gepard',
  'Rzetelna Firma',
  'Złote',
  'Euler Hermes',
  ]
  let photos = [
    '/img/logo-w/forbes_2024.jpg',
    '/img/logo-w/gazele_2.jpg',
    '/img/logo-w/cwb_3.jpg',
    '/img/logo-w/gepard_4.jpg',
    '/img/logo-w/rzetelna_firma_5.jpg',
    '/img/logo-w/zlote_6.jpg',
    '/img/logo-w/cwp_7.jpg']



  return (
    <>
    <h2 style={{textAlign:'center',marginTop:'50px'}} className="subtitle is-size-4" >Wyróżnienia</h2>
    <div style={{marginBottom:'50px'}}>
    <Swiper
      // install Swiper modules
      //onSwiper={(swiper) => //console.log(swiper)}
      modules={[Navigation, Pagination, A11y, Autoplay]}
      slidesPerView={3}
      spaceBetween={20}
      navigation={false}
      autoplay={true}
      loop={true}
      pagination={{ clickable: true }}
      breakpoints={{
      250: {
        slidesPerView: 1,
        spaceBetween: 20
      },

      640: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      850: {
        slidesPerView: 5,
        spaceBetween: 40
      }
    }}
    //onSlideChange={() => console.log('slide change')}
    >
    {names.map((el, i, isActive) => {
     return <SwiperSlide key={el}>

     {({ isNext }) => (
     <div className="grayhover" style={{padding:'5% 0%',margin:'0 20px',textAlign:'center'}}>
       <img className={isNext ? 'natives' : 'natives-blur'} width="80%" src={photos[i]} loading="lazy" alt={el} style={{marginBottom:'0px'}} />
     </div>
     )}

     </SwiperSlide>;
   })}
   <br />
   <br />

    </Swiper>
    </div>
    </>
  );
};
