import React from 'react';
import { Link, navigate } from 'gatsby'
const formData = require('form-data');

function encode(data) {
  const formData = new FormData()


  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData

}




class Form extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }


    handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    handleAttachment = e => {
      this.setState({ [e.target.name]: e.target.files[0] })
    }

    handleSubmit = e => {

      let fileinput = document.getElementById('fileinput');
      let file = fileinput.files[0];

      if ((file !== undefined) && (file.size < 1048576)){

          e.preventDefault()
          const form = e.target;

          let formdata
          formdata = encode({
           'form-name': form.getAttribute('name'),
           ...this.state,
         })


          fetch('/', {
            method: 'POST',
            body: formdata,
          })
            .then(() => {navigate('/kontakt/sukces/')
            }).catch(error => alert(error))

      } else if (file.size > 2048576) {
        alert('Plik jest zbyt duży. Maksymalna wielkość to 2MB, spróbuj ponownie z mniejszym plikiem');
        e.preventDefault()
      } else if (file === undefined) {
      e.preventDefault()
      const form = e.target;

      let formdata
      formdata = encode({
       'form-name': form.getAttribute('name'),
       ...this.state,
     })

      fetch('/', {
        method: 'POST',
        body: formdata,
      })
        .then(() => {navigate('/kontakt/sukces/')
        }).catch(error => alert(error))
    }

  }

  render() {
    return (
      <>

        <div className="box column in-half" style={{borderRadius:'10px',margin:'0% 5%',width:'90%',minHeight:'400px',padding:'3%',

      }}>




        {/*message*/}
        <div style={{textAlign:'center'}}>

        </div>
        <br></br>
        <form
          name="kontakt"
          encType="multipart/form-data"
          method="post"
          action="/kontakt/sukces/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
          style={{marginRight:'10%', maxWidth:'500px', marginLeft:'10%'}}
        >
          <h3 style={{fontSize:'20px',color:'#333333'}}><b>Wyślij wiadomość</b>:</h3>
          <br></br>

          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="kontakt" />
          <div hidden>
            <label>
              Don’t fill this out:{' '}

              <input name="bot-field"
              onChange={this.handleChange}
              />
            </label>
          </div>

          <div className="field">
            <label className="label" style={{color:'black',fontSize:'13px'}} htmlFor={'namex'}>
              Imię i Nazwisko<sup>*</sup>:
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'imię i nazwisko'}
                onChange={this.handleChange}
                id={'namex'}
                required="true"
              />
            </div>
          </div>

          <div className="field">
            <label className="label" style={{color:'black',fontSize:'13px'}} htmlFor={'emailx'}>
              Email<sup>*</sup>:
            </label>
            <div className="control">
              <input
                className="input"
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                id={'emailx'}
                required="true"
              />
            </div>
          </div>


          <div className="field">
            <label className="label" style={{color:'black',fontSize:'13px'}} htmlFor={'phonex'}>
              Numer telefonu:
            </label>
            <div className="control">
              <input
                className="input"
                type={'tel'}
                name={'numer telefonu'}
                onChange={this.handleChange}
                id={'phonex'}
              />
            </div>
          </div>


          <div className="field">
            <label className="label" style={{color:'black',fontSize:'13px'}} htmlFor={'msgx'}>
              Wiadomość<sup>*</sup>:
            </label>
            <div className="control">
              <textarea
                className="input"
                type={'text'}
                name={'wiadomosc'}
                onChange={this.handleChange}
                id={'msgx'}
                required="true"
                style={{minHeight:'100px'}}
              ></textarea>
            </div>
          </div>

          <div className="field">
          <label className="label" style={{color:'black',fontSize:'13px'}} htmlFor={'fileinput'}>
            Załącz plik<sup>*</sup>:
          </label>
            <div className="file standard-button">
              <label className="file-label">
                <input
                  className="standard-button"
                  type="file"
                  accept=".pdf,image/gif,image/bmp,image/png,image/jpeg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  name="attachment"
                  onChange={this.handleAttachment}
                  style={{height:'50px',width:'100%',padding:'10px',cursor:'pointer'}}
                  id ="fileinput"
                />
              </label>
            </div>
            <br />
            <label htmlFor="fileinput" style={{width:'100%',color:'#111',padding:'2px',marginLeft:'0px',fontWeight:'600',fontSize:'13px'}}> <sup>*</sup>Maksymalna wielkość pliku to <b>2mb</b>.<br /> Dopuszczalne formaty to .pdf, .jpg, .png, .bmp, .doc, .docx.</label>



          </div>

          <div className="control">
          <input required="true" type="checkbox" id="privacyX" name="privacy" value="true"/>&nbsp;&nbsp;
          <label style={{fontSize: '10px',color:'black'}}  htmlFor="privacyX"> Wyrażam zgodę na przetwarzanie mojego pliku i danych osobowych zgodnie z naszą <Link to="/polityka-prywatnosci/">polityką prywatności</Link>.</label><br></br><br></br>
          </div>

        <div style={{textAlign:'center'}}>
          <button className="button rexer-button is-size-5" onSubmit={this.handleSubmit} type="submit">
            wyślij
          </button>
        </div>

        </form>

        </div>






      </>
    )
  }
}
/*
function showFileSize() {
let fileinput = document.getElementById('fileinput');
let file = fileinput.files[0];

if (file !== undefined){
//console.log(file.size);
}

}
*/
export default Form;
