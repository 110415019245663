import React from "react";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import CpostOut from "../components/cpostOut";
import { gsap } from "gsap";
import Bio from "../components/bio";
import Form from "../components/form";
import Layout from "../components/layout";
import Slider from "../components/Slider-2";
import SEO from "../components/seo";
import Share from "../components/Share";
import { scale } from "../utils/typography";
import Newsletter from "../components/newsletter.js";
import WorkDetails from "../components/workDetails.js";
import CTA from "../components/cta.js";

class CareerPostTemplate extends React.Component {
  componentDidMount() {
    gsap.to("#a1", { y: -100, duration: 0 });
    gsap.to("#a1", { y: 0, duration: 2 });
  }

  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;
    //const { previous, next } = this.props.pageContext
    let posts = this.props.data.allMdx.edges;
    let filterPosts = [];

    posts.forEach((item, i) => {
      if (item.node.frontmatter.title !== post.frontmatter.title) {
        filterPosts.push(item);
      }
    });

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <div
          id="ctop"
          style={{
            height: "300px",
            width: "100%",
            padding: "10%",
            textAlign: "center",
            backgroundColor: "#5c5c5b",
            backgroundImage: "url(/img/t3.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        >
          <div id="a1" style={{ position: "relative", zIndex: "1" }}>
            <h1
              style={{
                marginTop: "0%",
                fontSize: "2em",
                color: "#00ff00",
                fontWeight: "800"
              }}
            >
              {post.frontmatter.title}
            </h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                color: "white"
              }}
            >
              {post.frontmatter.description}
            </p>
            {/*<span style={{fontSize:'11px',color:'whitesmoke'}}>{post.frontmatter.date}</span>*/}
          </div>
        </div>

        <div style={{ textAlign: "left", margin: "2% 10%" }}>
          <h2
            className="subtitle is-size-4"
            style={{
              borderLeft: "5px solid #00ff00",
              fontWeight: "600",
              textTransform: "uppercase",
              marginTop: "50px",
              paddingLeft: "10px"
            }}
          >
            Co oferujemy?
          </h2>
          <br />
          <WorkDetails />
        </div>

        <div
          id="render"
          style={{
            padding: "10%",
            backgroundColor: "#f6f6f6",
            paddingTop: "50px",
            paddingBottom: "50px"
          }}
        >
          <MDXRenderer>{post.body}</MDXRenderer>
          <br />

          <div style={{ margin: "2% 0px", textAlign: "center" }}>
            <a className="is-size-4 rexer-button button" href="#apply">
              Aplikuj
            </a>
            <h2
              className="subtitle"
              style={{
                marginBottom: "100px",
                marginTop: "50px",
                padding: "0% 5%"
              }}
            >
              Złóż aplikację za pomocą <b>formularza</b> lub wyślij nam{" "}
              <b>email</b> na nasz adres.
            </h2>
          </div>
          {/*<div style={{padding:'0%'}}>
        <Bio />
        <br />
        <Share
          title={post.frontmatter.title}
          slug={post.slug}
          excerpt={post.frontmatter.description}
          siteUrl="https://rexer.pl"
          pathPrefix="/kariera/"
        />
        </div>
        <Slider />*/}
          <div
            id="apply"
            className="columns"
            style={{ padding: "2% 5%", marginBottom: "0px" }}
          >
            <Form className="column" />
            <div
              className="column is-half"
              style={{
                textAlign: "center",
                justifyContent: "center",
                paddingLeft: "10%"
              }}
            >
              <div style={{ margin: "10%", marginBottom: "2.5%" }}>
                <img
                  alt="Rexer logo - inżynieria środowiska"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    height: "auto",
                    backgroundColor: "black",
                    padding: "5%",
                    borderRadius: "0px",
                    boxShadow: "10px 10px #00ff00"
                  }}
                  src="/img/logo-rexer.png"
                />
              </div>
              <br />
              <a
                style={{
                  backgroundColor: "rgba(0,0,0,0)",
                  border: "1px solid #00ff00",
                  color: "#333",
                  minWidth: "220px"
                }}
                className="button standard-button is-size-5"
                href="tel:+48 71 339 81 56"
              >
                +48 71 339 81 56
              </a>
              <br />
              <br />
              <a
                style={{
                  backgroundColor: "rgba(0,0,0,0)",
                  border: "1px solid #00ff00",
                  color: "#333",
                  minWidth: "220px"
                }}
                className="button standard-button is-size-5"
                href="mailto:biuro@rexer.pl"
              >
                biuro@rexer.pl
              </a>
              <br />
              <br />
              <br />
              <br />
              <p
                className="is-size-6"
                style={{ color: "#333", paddingTop: "0%" }}
              >
                <a className="is-size-4" href="/kontakt#location">
                  <b>REXER sp. z o.o.</b>
                  <br />
                  54-405 Wrocław, Polska <br />
                  ul. Legnicka 55F/330
                </a>
              </p>{" "}
              <br />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  alt="Kontakt"
                  loading="lazy"
                  src="/img/grafika-404.png"
                  style={{
                    minWidth: "150px",
                    maxWidth: "200px",
                    marginTop: "30px"
                  }}
                  height="auto"
                  width="auto"
                />
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <h2 className="subtitle"> Sprawdź resztę ofert pracy:</h2>
            <Link className="is-size-4 rexer-button button" to="/kariera#work">
              Kariera
            </Link>
          </div>
        </div>

        <div
          className="more-work"
          style={{ padding: "5%", backgroundColor: "#00ff00" }}
        >
          <h2 className="is-size-4 title">Pozostałe oferty pracy:</h2>
          <div
            id="scroll-wrapper"
            style={{
              overflowX: "scroll",
              display: "flex",
              flexWrap: "no-wrap"
            }}
          >
            <CpostOut posts={filterPosts} />
          </div>
        </div>
        <Newsletter />
        <CTA />
      </Layout>
    );
  }
}

export default CareerPostTemplate;

export const cpageQuery = graphql`
  query CareerPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        templateKey
        date(formatString: "DD. MM. YYYY")
        language
        description
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          language: { eq: "Polish" }
          templateKey: { eq: "career-page" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD. MM. YYYY")
            title
            language
            category
            description
            templateKey
          }
        }
      }
    }
  }
`;
